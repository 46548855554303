var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.open,
        confirmLoading: _vm.uploading,
      },
      on: {
        cancel: _vm.importExcelHandleCancel,
        ok: _vm.importExcelHandleChange,
      },
    },
    [
      _c(
        "a-spin",
        { attrs: { tip: "上传中...", spinning: _vm.uploading } },
        [
          _c(
            "a-upload-dragger",
            {
              attrs: {
                name: "file",
                "file-list": _vm.fileList,
                multiple: false,
                remove: _vm.handleRemove,
                "before-upload": _vm.beforeUpload,
              },
            },
            [
              _c(
                "p",
                { staticClass: "ant-upload-drag-icon" },
                [_c("a-icon", { attrs: { type: "inbox" } })],
                1
              ),
              _c("p", { staticClass: "ant-upload-text" }, [
                _vm._v(" 请将文件拖拽到此处上传❥(^_-) "),
              ]),
              _c("p", { staticClass: "ant-upload-hint" }, [
                _vm._v(" 文件的命名规则: mapName_mapVersion_mapId.bin "),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }