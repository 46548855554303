<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirmLoading="uploading"
    @cancel="importExcelHandleCancel"
    @ok="importExcelHandleChange"
  >
    <a-spin tip="上传中..." :spinning="uploading">
      <a-upload-dragger
        name="file"
        :file-list="fileList"
        :multiple="false"
        :remove="handleRemove"
        :before-upload="beforeUpload"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          请将文件拖拽到此处上传❥(^_-)
        </p>
        <p class="ant-upload-hint">
          文件的命名规则: mapName_mapVersion_mapId.bin
        </p>
      </a-upload-dragger>
    </a-spin>
  </a-modal>
</template>

<script>
import { importTemplate } from '@/api/iot/oilCard'
import { syncMapdataToLane } from '@/api/iot/raLane'

export default {
  name: 'ImportExcel',
  props: {},
  components: {},
  data() {
    return {
      title: '车道数据导入',
      open: false,
      uploadStatus: '',
      fileList: [],
      // 是否禁用上传
      uploading: false,
      updateSupport: 0
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  methods: {
    /** 导入excel窗体关闭 */
    importExcelHandleCancel(e) {
      this.open = false
      this.fileList = []
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 下载模板操作 */
    importTemplate() {
      importTemplate().then(response => {
        this.download(response.msg)
      })
    },
    /** 导入excel窗体开启 */
    importExcelHandleOpen(e) {
      this.open = true
    },
    beforeUpload(file) {
      this.fileList.push(file)
      return false
    },
    /** 导入excel */
    importExcelHandleChange() {
      console.log('importExcelHandleChange')
      this.uploading = true
      const { fileList } = this
      const formData = new FormData()
      if (fileList.length > 1) {
        this.$warning({
          title: 'Warning',
          content: '最多只能上传一个文件且必须是excel文件和zip文件'
        })
        this.uploading = false
        return
      } else if (fileList.length === 1) {
        formData.append('file', fileList[0])
      } else {
        this.$warning({
          title: 'Warning',
          content: '请选择上传文件！'
        })
      }

      // formData.append('updateSupport', this.updateSupport)
      syncMapdataToLane(formData).then(response => {
        this.fileList = []
        this.$warning({
          title: 'Warning',
          content: response.msg
        })
        this.open = false
        this.$emit('ok')
        this.uploading = false
      }).catch(() => {
        this.uploading = false
      })
    },

    handleRemove() {
      this.fileList = []
      this.uploading = false
    }
  }
}
</script>
<style lang="less" scoped>
.download-template {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
